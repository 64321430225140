// Berechnet das Alter eines Datums und liefert das Ergebnis in Form von "x Jahre" bzw. "x Jahre und y Monate". Bei Fehlern gibts null zurück
function bestimmeAlter(dateStr, mitMonaten = false) {
    var datum = new Date(dateStr);
    var heute = new Date();

    // unvollständige Eingabe?
    if (isNaN(datum) || datum.getTime() > heute.getTime()) {
        return null;
    }

    // Differenz bilden
    var jahre = heute.getFullYear() - datum.getFullYear();
    var tage = heute.getDate() - datum.getDate();
    var monate = heute.getMonth() - datum.getMonth();

    if (monate < 0 || (monate == 0 && heute.getDate() < datum.getDate())) {
        jahre--;
        monate += 12;
    }
    if (tage < 0) {
        monate--;
    }

    j = jahre + (jahre == 1 ? " Jahr" : " Jahre");
    m = monate == 0 ? "" : " und " + monate + (monate == 1 ? " Monat" : " Monate");
    return j + (mitMonaten ? m : "");
}
